<template>
  <li class="c-sidebar-nav-item a-sidebar-nav-item">
    <slot>
      <CLink
        :class="linkClasses"
        v-bind="computedLinkProps"
      >
        <CIcon v-if="icon" v-bind="computedIcon"/>
        <i v-if="fontIcon" :class="['c-sidebar-nav-icon', fontIcon]"/>
        <img v-if="imgURL" :src="imgURL" alt="" class="c-sidebar-nav-img">
        {{name}}
        <i :class="['fas', 'pin', 'mx-auto', pinned ? 'fa-minus-circle' : 'fa-thumbtack']" v-c-tooltip="{content: pinned ? '取消固定' : '固定', placement: 'top'}" @click="$store.dispatch('PinNavigation', {Type: tag[0], Item: tag[1], Pin: pinned})"></i>
        <CBadge
          v-if="badge"
          v-bind="Object.assign({}, badge, { text: null })"
        >
          {{badge.text}}
        </CBadge>
      </CLink>
    </slot>
  </li>
</template>

<script>
import CLink, { props as linkProps } from '@coreui/vue/src/components/link/CLink'
import CBadge from '@coreui/vue/src/components/badge/CBadge'
import CIcon from '@coreui/icons-vue/src/CIconRaw.vue'

const props = Object.assign(
  {},
  linkProps,
  {
    exact: {
      type: Boolean,
      default: true
    },
    activeClass: {
      type: String,
      default: 'c-active'
    }
  },
  {
    tag: Array,
    name: String,
    icon: [String, Object],
    fontIcon: String,
    imgURL: String,
    badge: Object,
    addLinkClasses: [String, Array, Object],
    label: Boolean,
    pinned: Boolean,
    color: String
  }
)

export default {
  name: 'AlterSidebarNavItem',
  components: {
    CLink,
    CBadge,
    CIcon
  },
  props,
  computed: {
    computedLinkProps () {
      return Object.keys(linkProps).reduce((props, key) => {
        props[key] = this[key]
        return props
      }, {})
    },
    linkClasses () {
      return [
        this.label ? 'c-sidebar-nav-label' : 'c-sidebar-nav-link',
        this.color && `c-sidebar-nav-link-${this.color}`,
        this.addLinkClasses
      ]
    },
    computedIcon () {
      if (typeof this.icon === 'object') {
        const key = this.icon.size ? 'class' : 'customClasses'
        return {
          ...this.icon,
          [`${key}`]: this.icon.customClasses ||
          ['c-sidebar-nav-icon', this.icon.className]
        }
      } else {
        return { customClasses: 'c-sidebar-nav-icon', name: this.icon }
      }
    }
  }
}
</script>

<style scoped>
.c-sidebar-nav-img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 19px;
  filter: brightness(0) invert(100%);
}
.c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-img {
  filter: brightness(1) invert(0);
}
</style>
