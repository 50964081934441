import i18n from '@/plugins/i18n'
import store from './store'

export default (locale) => {
  return [
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Dashboard/Info',
      name: i18n.messages[locale].Navigation['Dashboard/Info'],
      to: '/',
      icon: 'cil-speedometer',
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Dashboard/UMEANS_ECRM',
      name: i18n.messages[locale].Navigation['Dashboard/UMEANS_ECRM'],
      to: '/ecrm',
      imgURL: 'https://cdn.marketingless.com/sites/UMEANS_MEAL/umeans_icon.png',
      badge: {
        text: '預覽',
        color: 'info',
        shape: 'pill'
      }
    },
    {
      _name: 'CSidebarNavTitle',
      _navName: 'Products',
      _children: ['Products']
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Products/Manage',
      tag: ['Products', 'Manage'],
      name: i18n.messages[locale].Navigation['Products/Manage'],
      route: '/products/list',
      icon: 'cil-star',
      items: [
        {
          permissionLabel: 'Manage',
          _navName: 'Products/List',
          tag: ['Products', 'List'],
          name: i18n.messages[locale].Navigation['Products/List'],
          to: '/products/list'
        },
        {
          permissionLabel: 'Manage',
          _navName: 'Products/Add',
          tag: ['Products', 'Add'],
          name: i18n.messages[locale].Navigation['Products/Add'],
          to: '/products/add'
        },
        // {
        //   permissionLabel: 'OptionManage',
        //   _navName: 'Products/OptionManage',
        //   tag: ['Products', 'OptionManage'],
        //   name: i18n.messages[locale].Navigation['Products/OptionManage'],
        //   to: '/products/options'
        // }
      ]
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Products/Categories',
      tag: ['Products', 'Categories'],
      name: i18n.messages[locale].Navigation['Products/Categories'],
      route: '/products/category',
      icon: 'cil-layers',
      items: [
        {
          permissionLabel: 'Categories',
          _navName: 'Products/ListCategory',
          tag: ['Products', 'ListCategory'],
          name: i18n.messages[locale].Navigation['Products/ListCategory'],
          to: '/products/category/list'
        },
        {
          permissionLabel: 'Categories',
          _navName: 'Products/AddCategory',
          tag: ['Products', 'AddCategory'],
          name: i18n.messages[locale].Navigation['Products/AddCategory'],
          to: '/products/category/add'
        }
      ]
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Products/ManagePlan',
      tag: ['Products', 'ManagePlan'],
      name: i18n.messages[locale].Navigation['Products/ManagePlan'],
      route: '/products/plan',
      icon: 'cil-star',
      items: [
        {
          permissionLabel: 'ManagePlan',
          _navName: 'Products/ListPlan',
          tag: ['Products', 'ListPlan'],
          name: i18n.messages[locale].Navigation['Products/ListPlan'],
          to: '/products/plan/list'
        },
        {
          permissionLabel: 'ManagePlan',
          _navName: 'Products/AddPlan',
          tag: ['Products', 'AddPlan'],
          name: i18n.messages[locale].Navigation['Products/AddPlan'],
          to: '/products/plan/add'
        }
      ]
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Products/OfferItems',
      tag: ['Products', 'OfferItems'],
      name: i18n.messages[locale].Navigation['Products/OfferItems'],
      route: '/offerItems',
      icon: 'cil-gem',
      items: [
        {
          permissionLabel: 'Gift',
          _navName: 'Products/Gift',
          tag: ['Products', 'Gift'],
          name: i18n.messages[locale].Navigation['Products/Gift'],
          to: '/offerItems/gift'
        },
        {
          permissionLabel: 'ExtraItems',
          _navName: 'Products/ExtraItems',
          tag: ['Products', 'ExtraItems'],
          name: i18n.messages[locale].Navigation['Products/ExtraItems'],
          to: '/offerItems/addon'
        }
      ]
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Products/Stock',
      tag: ['Products', 'Stock'],
      name: i18n.messages[locale].Navigation['Products/Stock'],
      route: '/products/stock',
      fontIcon: 'fas fa-boxes',
      items: [
        {
          permissionLabel: 'StockManage',
          _navName: 'Products/StockManage',
          tag: ['Products', 'StockManage'],
          name: i18n.messages[locale].Navigation['Products/StockManage'],
          to: '/products/stock/list'
        },
        {
          permissionLabel: 'SemiFinishedProducts',
          _navName: 'Products/SemiFinishedProducts',
          tag: ['Products', 'SemiFinishedProducts'],
          name: i18n.messages[locale].Navigation['Products/SemiFinishedProducts'],
          to: '/products/semi-finished-products/list'
        },
        {
          permissionLabel: 'Element',
          _navName: 'Products/Element',
          tag: ['Products', 'Element'],
          name: i18n.messages[locale].Navigation['Products/Element'],
          to: '/products/element/list'
        },
        {
          permissionLabel: 'StockImport',
          _navName: 'Products/StockImport',
          tag: ['Products', 'StockImport'],
          name: i18n.messages[locale].Navigation['Products/StockImport'],
          to: '/products/stock/import'
        }
      ]
    },
    {
      _name: 'CSidebarNavTitle',
      _navName: 'Channel',
      _children: ['Channel']
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Channel/B2B',
      tag: ['Channel', 'B2B'],
      name: i18n.messages[locale].Navigation['Channel/B2B'],
      to: '/channel/b2b',
      icon: 'cil-list'
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Channel/Web',
      tag: ['Channel', 'Web'],
      name: i18n.messages[locale].Navigation['Channel/Web'],
      to: '/channel/web',
      icon: 'cil-list'
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Channel/Subscription',
      tag: ['Channel', 'Subscription'],
      name: i18n.messages[locale].Navigation['Channel/Subscription'],
      to: '/channel/subscription',
      icon: 'cil-list'
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Channel/SinglePage',
      tag: ['Channel', 'SinglePage'],
      name: i18n.messages[locale].Navigation['Channel/SinglePage'],
      route: '/channel/landing',
      icon: 'cil-cart',
      items: [
        {
          permissionLabel: 'SinglePage',
          _navName: 'Products/SinglePageList',
          tag: ['Products', 'SinglePageList'],
          name: i18n.messages[locale].Navigation['Products/SinglePageList'],
          to: '/channel/landing/list'
        },
        {
          permissionLabel: 'SinglePage',
          _navName: 'Products/SinglePageAdd',
          tag: ['Products', 'SinglePageAdd'],
          name: i18n.messages[locale].Navigation['Products/SinglePageAdd'],
          to: '/channel/landing/add'
        }
      ]
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Channel/Store',
      tag: ['Channel', 'Store'],
      name: i18n.messages[locale].Navigation['Channel/Store'],
      route: '/channel/store',
      fontIcon: 'fas fa-store-alt',
      items: [
        {
          permissionLabel: 'Store',
          _navName: 'Channel/StoreList',
          tag: ['Channel', 'StoreList'],
          name: i18n.messages[locale].Navigation['Channel/StoreList'],
          to: '/channel/store/list'
        },
        {
          permissionLabel: 'Store',
          _navName: 'Channel/StoreAdd',
          tag: ['Channel', 'StoreAdd'],
          name: i18n.messages[locale].Navigation['Channel/StoreAdd'],
          to: '/channel/store/add'
        }
      ]
    },
    {
      _name: 'CSidebarNavTitle',
      _navName: 'Order',
      _children: ['Order']
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Order/List',
      tag: ['Order', 'List'],
      name: i18n.messages[locale].Navigation['Order/List'],
      to: '/order/list',
      icon: 'cil-list'
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Order/ManageProcess',
      tag: ['Order', 'ManageProcess'],
      name: i18n.messages[locale].Navigation['Order/ManageProcess'],
      route: '/order/process',
      fontIcon: 'fas fa-tasks',
      items: [
        {
          permissionLabel: 'ManageProcess',
          _navName: 'Order/Unpaid',
          tag: ['Order', 'Unpaid'],
          name: i18n.messages[locale].Navigation['Order/Unpaid'],
          to: '/order/process/unpaid',
          badge: {
            color: store.getters.orderStatusStatistics.Unpaid.TotalItems > 0 ? 'warning' : 'light',
            text: store.getters.orderStatusStatistics.Unpaid.TotalItems || 0,
            shape: 'pill'
          }
        },
        {
          permissionLabel: 'ManageProcess',
          _navName: 'Order/Unshipped',
          tag: ['Order', 'Unshipped'],
          name: i18n.messages[locale].Navigation['Order/Unshipped'],
          to: '/order/process/unshipped',
          badge: {
            color: store.getters.orderStatusStatistics.UnShipment.TotalItems > 0 ? 'warning' : 'light',
            text: store.getters.orderStatusStatistics.UnShipment.TotalItems || 0,
            shape: 'pill'
          }
        },
        {
          permissionLabel: 'ManageProcess',
          _navName: 'Order/Shipment',
          tag: ['Order', 'Shipment'],
          name: i18n.messages[locale].Navigation['Order/Shipment'],
          to: '/order/process/shipment',
          badge: {
            color: store.getters.orderStatusStatistics.EnableShipment.TotalItems > 0 ? 'success' : 'light',
            text: store.getters.orderStatusStatistics.EnableShipment.TotalItems || 0,
            shape: 'pill'
          }
        },
        {
          permissionLabel: 'ManageProcess',
          _navName: 'Order/Abnormal',
          tag: ['Order', 'Abnormal'],
          name: i18n.messages[locale].Navigation['Order/Abnormal'],
          to: '/order/process/abnormal',
          badge: {
            color: store.getters.orderStatusStatistics.Abnormal.TotalItems > 0 ? 'danger' : 'light',
            text: store.getters.orderStatusStatistics.Abnormal.TotalItems || 0,
            shape: 'pill'
          }
        },
        {
          permissionLabel: 'ManageProcess',
          _navName: 'Order/Return',
          tag: ['Order', 'Return'],
          name: i18n.messages[locale].Navigation['Order/Return'],
          to: '/order/process/return',
          badge: {
            color: store.getters.orderStatusStatistics.EnableRefund.TotalItems > 0 ? 'info' : 'light',
            text: store.getters.orderStatusStatistics.EnableRefund.TotalItems || 0,
            shape: 'pill'
          }
        },
        {
          permissionLabel: 'ManageProcess',
          _navName: 'Order/Message',
          tag: ['Order', 'Message'],
          name: i18n.messages[locale].Navigation['Order/Message'],
          to: '/order/process/message'
        }
      ]
    },
    // {
    //   _name: 'AlterSidebarNavDropdown',
    //   _navName: 'Order/ManageRegion',
    //   name: i18n.messages[locale].Navigation['Order/ManageRegion'],
    //   route: '/order/region',
    //   fontIcon: 'fas fa-globe-asia',
    //   items: [
    //     {
    //       permissionLabel: 'ManageRegion',
    //       _navName: 'Order/MainRegion',
    //       name: i18n.messages[locale].Navigation['Order/MainRegion'],
    //       to: '/order/region/main'
    //     },
    //     {
    //       permissionLabel: 'ManageRegion',
    //       _navName: 'Order/AbroadRegion',
    //       name: i18n.messages[locale].Navigation['Order/AbroadRegion'],
    //       to: '/order/region/abroad'
    //     }
    //   ]
    // },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Order/SubscriptionList',
      tag: ['Order', 'SubscriptionList'],
      name: i18n.messages[locale].Navigation['Order/SubscriptionList'],
      to: '/order/subscription',
      icon: 'cil-newspaper'
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Order/LogisticsOrder',
      tag: ['Order', 'LogisticsOrder'],
      name: i18n.messages[locale].Navigation['Order/LogisticsOrder'],
      to: '/order/logistics',
      icon: 'cil-truck'
    },
    {
      _name: 'CSidebarNavTitle',
      _navName: 'Video',
      _children: ['Video']
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Video/Manage',
      tag: ['Video', 'Manage'],
      name: i18n.messages[locale].Navigation['Video/Manage'],
      route: '/video',
      icon: 'cil-media-play',
      items: [
        {
          permissionLabel: 'List',
          _navName: 'Video/List',
          tag: ['Video', 'List'],
          name: i18n.messages[locale].Navigation['Video/List'],
          to: '/video/list'
        },
        {
          permissionLabel: 'Add',
          _navName: 'Video/Add',
          tag: ['Video', 'Add'],
          name: i18n.messages[locale].Navigation['Video/Add'],
          to: '/video/add'
        }
      ]
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Video/Category',
      tag: ['Video', 'Category'],
      name: i18n.messages[locale].Navigation['Video/Category'],
      route: '/video',
      icon: 'cil-featured-playlist',
      items: [
        {
          permissionLabel: 'ListCategory',
          _navName: 'Video/ListCategory',
          tag: ['Video', 'ListCategory'],
          name: i18n.messages[locale].Navigation['Video/ListCategory'],
          to: '/video/category/list'
        },
        {
          permissionLabel: 'AddCategory',
          _navName: 'Video/AddCategory',
          tag: ['Video', 'AddCategory'],
          name: i18n.messages[locale].Navigation['Video/AddCategory'],
          to: '/video/category/add'
        }
      ]
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Video/VimeoStore',
      tag: ['Video', 'VimeoStore'],
      name: i18n.messages[locale].Navigation['Video/VimeoStore'],
      to: '/video/vimeo/store',
      icon: 'cib-vimeo-v'
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Video/MediaStorage',
      tag: ['Video', 'MediaStorage'],
      name: i18n.messages[locale].Navigation['Video/MediaStorage'],
      to: '/video/storage',
      fontIcon: 'fas fa-file-video'
    },
    {
      _name: 'CSidebarNavTitle',
      _navName: 'Organization',
      _children: ['Organization']
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Organization/Member',
      tag: ['Organization', 'Member'],
      name: i18n.messages[locale].Navigation['Organization/Member'],
      route: '/organization/member',
      icon: 'cil-user',
      items: [
        {
          permissionLabel: 'ListMember',
          _navName: 'Organization/ListMember',
          tag: ['Organization', 'ListMember'],
          name: i18n.messages[locale].Navigation['Organization/ListMember'],
          to: '/organization/member/list'
        },
        {
          permissionLabel: 'MemberLevel',
          _navName: 'Organization/MemberLevel',
          tag: ['Organization', 'MemberLevel'],
          name: i18n.messages[locale].Navigation['Organization/MemberLevel'],
          to: '/organization/member/level'
        },
        {
          permissionLabel: 'MemberBonus',
          _navName: 'Organization/MemberBonus',
          tag: ['Organization', 'MemberBonus'],
          name: i18n.messages[locale].Navigation['Organization/MemberBonus'],
          to: '/organization/member/bonus'
        },
        {
          permissionLabel: 'ListMember',
          _navName: 'Organization/MemberLoginProvider',
          tag: ['Organization', 'MemberLoginProvider'],
          name: i18n.messages[locale].Navigation['Organization/MemberLoginProvider'],
          to: '/organization/member/providers'
        },
        // {
        //   permissionLabel: 'MemberWallet',
        //   _navName: 'Organization/MemberWallet',
        //   tag: ['Organization', 'MemberWallet'],
        //   name: i18n.messages[locale].Navigation['Organization/MemberWallet'],
        //   to: '/organization/member/wallet'
        // }
      ]
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Organization/Manage',
      tag: ['Organization', 'Manage'],
      name: i18n.messages[locale].Navigation['Organization/Manage'],
      route: '/organization/manage',
      icon: 'cil-people',
      items: [
        {
          permissionLabel: 'ListManage',
          _navName: 'Organization/ListManage',
          tag: ['Organization', 'ListManage'],
          name: i18n.messages[locale].Navigation['Organization/ListManage'],
          to: '/organization/manage/list'
        }
      ]
    },
    {
      _name: 'CSidebarNavTitle',
      _navName: 'Offer',
      _children: ['Offer']
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Offer/Coupon',
      tag: ['Offer', 'Coupon'],
      name: i18n.messages[locale].Navigation['Offer/Coupon'],
      to: '/offer/coupon/list',
      icon: 'cil-tags'
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Offer/Activity',
      tag: ['Offer', 'Activity'],
      name: i18n.messages[locale].Navigation['Offer/Activity'],
      to: '/offer/activity/list',
      icon: 'cil-bullhorn'
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Offer/ExtraItems',
      tag: ['Offer', 'ExtraItems'],
      name: i18n.messages[locale].Navigation['Offer/ExtraItems'],
      to: '/offer/extraItems/list',
      icon: 'cil-money'
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Offer/DiscountTicket',
      tag: ['Offer', 'DiscountTicket'],
      name: i18n.messages[locale].Navigation['Offer/DiscountTicket'],
      to: '/offer/discountTicket/list',
      fontIcon: 'fas fa-ticket-alt'
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Offer/OptionalSelect',
      tag: ['Offer', 'OptionalSelect'],
      name: i18n.messages[locale].Navigation['Offer/OptionalSelect'],
      to: '/offer/optionalSelect/list',
      fontIcon: 'fas fa-object-group'
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Offer/Raffle',
      tag: ['Offer', 'Raffle'],
      name: i18n.messages[locale].Navigation['Offer/Raffle'],
      route: '/offer/raffle',
      fontIcon: 'fas fa-trophy',
      items: [
        {
          permissionLabel: 'RaffleActivity',
          _navName: 'Offer/RaffleActivity',
          tag: ['Offer', 'RaffleActivity'],
          name: i18n.messages[locale].Navigation['Offer/RaffleActivity'],
          to: '/offer/raffle'
        },
        {
          permissionLabel: 'RaffleTicket',
          _navName: 'Offer/RaffleTicket',
          tag: ['Offer', 'RaffleTicket'],
          name: i18n.messages[locale].Navigation['Offer/RaffleTicket'],
          to: '/offer/raffle/ticket'
        },
      ]
    },
    {
      _name: 'CSidebarNavTitle',
      _navName: 'Report',
      _children: ['Report']
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Report/Profit',
      tag: ['Report', 'Profit'],
      name: i18n.messages[locale].Navigation['Report/Profit'],
      to: '/report/profit',
      icon: 'cil-chart-line'
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Report/SalePage',
      tag: ['Report', 'SalePage'],
      name: i18n.messages[locale].Navigation['Report/SalePage'],
      to: '/report/salePage',
      icon: 'cil-cart'
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Report/Site',
      tag: ['Report', 'Site'],
      name: i18n.messages[locale].Navigation['Report/Site'],
      to: '/report/site',
      icon: 'cil-calculator'
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Report/Shipment',
      tag: ['Report', 'Shipment'],
      name: i18n.messages[locale].Navigation['Report/Shipment'],
      route: '/report/shipment',
      icon: 'cil-truck',
      items: [
        {
          permissionLabel: 'Shipment',
          _navName: 'Report/ShipmentDaily',
          tag: ['Report', 'ShipmentDaily'],
          name: i18n.messages[locale].Navigation['Report/ShipmentDaily'],
          to: '/report/shipment/summary'
        },
        {
          permissionLabel: 'Shipment',
          _navName: 'Report/ShipmentSKU',
          tag: ['Report', 'ShipmentSKU'],
          name: i18n.messages[locale].Navigation['Report/ShipmentSKU'],
          to: '/report/shipment/sku'
        },
        {
          permissionLabel: 'Shipment',
          _navName: 'Report/ShipmentLogistics',
          tag: ['Report', 'ShipmentLogistics'],
          name: i18n.messages[locale].Navigation['Report/ShipmentLogistics'],
          to: '/report/shipment/logistics'
        }
      ]
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Report/Order',
      tag: ['Report', 'Order'],
      name: i18n.messages[locale].Navigation['Report/Order'],
      route: '/report/order',
      icon: 'cil-chart',
      items: [
        {
          permissionLabel: 'Order',
          _navName: 'Report/OrderCustomerHeatTime',
          tag: ['Report', 'OrderCustomerHeatTime'],
          name: i18n.messages[locale].Navigation['Report/OrderCustomerHeatTime'],
          to: '/report/order/heatTime'
        },
        {
          permissionLabel: 'Order',
          _navName: 'Report/OrderDaily',
          tag: ['Report', 'OrderDaily'],
          name: i18n.messages[locale].Navigation['Report/OrderDaily'],
          to: '/report/order/daily'
        },
        {
          permissionLabel: 'Order',
          _navName: 'Report/OrderMonthly',
          tag: ['Report', 'OrderMonthly'],
          name: i18n.messages[locale].Navigation['Report/OrderMonthly'],
          to: '/report/order/monthly'
        },
        {
          permissionLabel: 'Order',
          _navName: 'Report/OrderEngagement',
          tag: ['Report', 'OrderEngagement'],
          name: i18n.messages[locale].Navigation['Report/OrderEngagement'],
          to: '/report/order/engagement'
        },
        {
          permissionLabel: 'Order',
          _navName: 'Report/OrderOffer',
          tag: ['Report', 'OrderOffer'],
          name: i18n.messages[locale].Navigation['Report/OrderOffer'],
          to: '/report/order/offer'
        },
        {
          permissionLabel: 'Order',
          _navName: 'Report/OrderPayment',
          tag: ['Report', 'OrderPayment'],
          name: i18n.messages[locale].Navigation['Report/OrderPayment'],
          to: '/report/order/payment'
        },
        {
          permissionLabel: 'Order',
          _navName: 'Report/OrderLogistics',
          tag: ['Report', 'OrderLogistics'],
          name: i18n.messages[locale].Navigation['Report/OrderLogistics'],
          to: '/report/order/logistics'
        }
      ]
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Report/Product',
      tag: ['Report', 'Product'],
      name: i18n.messages[locale].Navigation['Report/Product'],
      route: '/report/product',
      icon: 'cil-chart-pie',
      items: [
        {
          permissionLabel: 'Product',
          _navName: 'Report/ProductRank',
          tag: ['Report', 'ProductRank'],
          name: i18n.messages[locale].Navigation['Report/ProductRank'],
          to: '/report/product/rank'
        },
        {
          permissionLabel: 'Product',
          _navName: 'Report/SKURank',
          tag: ['Report', 'SKURank'],
          name: i18n.messages[locale].Navigation['Report/SKURank'],
          to: '/report/product/sku'
        },
        {
          permissionLabel: 'Product',
          _navName: 'Report/ProductCategory',
          tag: ['Report', 'ProductCategory'],
          name: i18n.messages[locale].Navigation['Report/ProductCategory'],
          to: '/report/product/category'
        }
      ]
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Report/Member',
      tag: ['Report', 'Member'],
      name: i18n.messages[locale].Navigation['Report/Member'],
      route: '/report/organization/member',
      icon: 'cil-user',
      items: [
        {
          // permissionLabel: 'MemberRegisterDaily',
          permissionLabel: 'Member',
          _navName: 'Report/MemberRegisterDaily',
          tag: ['Report', 'MemberRegisterDaily'],
          name: i18n.messages[locale].Navigation['Report/MemberRegisterDaily'],
          to: '/report/organization/member/dailyRegister'
        },
        {
          // permissionLabel: 'MemberRegisterMonthly',
          permissionLabel: 'Member',
          _navName: 'Report/MemberRegisterMonthly',
          tag: ['Report', 'MemberRegisterMonthly'],
          name: i18n.messages[locale].Navigation['Report/MemberRegisterMonthly'],
          to: '/report/organization/member/monthlyRegister'
        },
        {
          // permissionLabel: 'MemberTagStatistics',
          permissionLabel: 'Member',
          _navName: 'Report/MemberTagStatistics',
          tag: ['Report', 'MemberTagStatistics'],
          name: i18n.messages[locale].Navigation['Report/MemberTagStatistics'],
          to: '/report/organization/member/tagStatistics'
        },
        {
          // permissionLabel: 'MemberLevelStatistics',
          permissionLabel: 'Member',
          _navName: 'Report/MemberLevelStatistics',
          tag: ['Report', 'MemberLevelStatistics'],
          name: i18n.messages[locale].Navigation['Report/MemberLevelStatistics'],
          to: '/report/organization/member/levelStatistics'
        },
        // {
        //   permissionLabel: 'MemberLevelChangeRecordStatistics',
        //   permissionLabel: 'Member',
        //   _navName: 'Report/MemberLevelChangeRecordStatistics',
        //   tag: ['Report', 'MemberLevelChangeRecordStatistics'],
        //   name: i18n.messages[locale].Navigation['Report/MemberLevelChangeRecordStatistics'],
        //   to: '/report/organization/member/levelChange'
        // },
        {
          // permissionLabel: 'MemberLevelChangeRecordStatistics',
          permissionLabel: 'Member',
          _navName: 'Report/MemberLevelChangeTrend',
          tag: ['Report', 'MemberLevelChangeTrend'],
          name: i18n.messages[locale].Navigation['Report/MemberLevelChangeTrend'],
          to: '/report/organization/member/levelChangeTrend'
        },
        {
          // permissionLabel: 'MemberBonusRecordStatistics',
          permissionLabel: 'Member',
          _navName: 'Report/MemberBonusRecordStatistics',
          tag: ['Report', 'MemberBonusRecordStatistics'],
          name: i18n.messages[locale].Navigation['Report/MemberBonusRecordStatistics'],
          to: '/report/organization/member/bonusRecord'
        },
      ]
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Report/Insight',
      tag: ['Report', 'Insight'],
      name: i18n.messages[locale].Navigation['Report/Insight'],
      route: '/report/insight',
      icon: 'cil-graph',
      items: [
        {
          permissionLabel: 'Insight',
          _navName: 'Report/MemberLevelRepurchase',
          tag: ['Report', 'MemberLevelRepurchase'],
          name: i18n.messages[locale].Navigation['Report/MemberLevelRepurchase'],
          to: '/report/insight/repurchase/level',
          badge: {
            color: 'success',
            text: '進階',
            shape: 'pill'
          }
        }
      ]
    },
    {
      _name: 'CSidebarNavTitle',
      _navName: 'Marketing',
      _children: ['Marketing']
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Marketing/Tracking',
      tag: ['Marketing', 'Tracking'],
      name: i18n.messages[locale].Navigation['Marketing/Tracking'],
      to: '/marketing/tracking',
      icon: 'cil-chart-line'
    },
    {
      _name: 'CSidebarNavTitle',
      _navName: 'Post',
      _children: ['Content']
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Post/Manage',
      tag: ['Post', 'Manage'],
      name: i18n.messages[locale].Navigation['Post/Manage'],
      route: '/post',
      icon: 'cil-pencil',
      items: [
        {
          permissionLabel: 'List',
          _navName: 'Post/List',
          tag: ['Post', 'List'],
          name: i18n.messages[locale].Navigation['Post/List'],
          to: '/post/list'
        },
        {
          permissionLabel: 'Audit',
          _navName: 'Post/Audit',
          tag: ['Post', 'Audit'],
          name: i18n.messages[locale].Navigation['Post/Audit'],
          to: '/post/audit'
        },
        {
          permissionLabel: 'Add',
          _navName: 'Post/Add',
          tag: ['Post', 'Add'],
          name: i18n.messages[locale].Navigation['Post/Add'],
          to: '/post/add'
        }
      ]
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Post/Category',
      tag: ['Post', 'Category'],
      name: i18n.messages[locale].Navigation['Post/Category'],
      route: '/post/category',
      icon: 'cil-notes',
      items: [
        {
          permissionLabel: 'ListCategory',
          _navName: 'Post/ListCategory',
          tag: ['Post', 'ListCategory'],
          name: i18n.messages[locale].Navigation['Post/ListCategory'],
          to: '/post/category/list'
        },
        {
          permissionLabel: 'AddCategory',
          _navName: 'Post/AddCategory',
          tag: ['Post', 'AddCategory'],
          name: i18n.messages[locale].Navigation['Post/AddCategory'],
          to: '/post/category/add'
        }
      ]
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Content/CustomPage',
      tag: ['Content', 'CustomPage'],
      name: i18n.messages[locale].Navigation['Content/CustomPage'],
      route: '/content/page',
      fontIcon: 'fas fa-file-alt',
      items: [
        {
          permissionLabel: 'CustomPage',
          _navName: 'Content/CustomPageList',
          tag: ['Content', 'CustomPageList'],
          name: i18n.messages[locale].Navigation['Content/CustomPageList'],
          to: '/content/page/list'
        },
      ]
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'System/ThemeBuilding',
      tag: ['System', 'ThemeBuilding'],
      name: i18n.messages[locale].Navigation['System/ThemeBuilding'],
      route: '/content/builder',
      fontIcon: 'fas fa-palette',
      items: [
        {
          permissionLabel: 'ThemeBuilding',
          _navName: 'System/ThemeBuildingPage',
          tag: ['System', 'ThemeBuildingPage'],
          name: i18n.messages[locale].Navigation['System/ThemeBuildingPage'],
          to: '/content/builder',
        },
        {
          permissionLabel: 'ThemeBuilding',
          _navName: 'System/ThemeBuildingHome',
          tag: ['System', 'ThemeBuildingHome'],
          name: i18n.messages[locale].Navigation['System/ThemeBuildingHome'],
          to: '/content/builder/home'
        },
        {
          permissionLabel: 'ThemeBuilding',
          _navName: 'System/ThemeBuildingAbout',
          tag: ['System', 'ThemeBuildingAbout'],
          name: i18n.messages[locale].Navigation['System/ThemeBuildingAbout'],
          to: '/content/builder/about',
        },
        {
          permissionLabel: 'ThemeBuilding',
          _navName: 'System/ThemeBuildingPrivatePolicy',
          tag: ['System', 'ThemeBuildingPrivatePolicy'],
          name: i18n.messages[locale].Navigation['System/ThemeBuildingPrivatePolicy'],
          to: '/content/builder/private_policy',
        },
        {
          permissionLabel: 'ThemeBuilding',
          _navName: 'System/ThemeBuildingTerm',
          tag: ['System', 'ThemeBuildingTerm'],
          name: i18n.messages[locale].Navigation['System/ThemeBuildingTerm'],
          to: '/content/builder/terms',
        },
      ]
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Post/Manage',
      tag: ['Content', 'FAQ'],
      name: i18n.messages[locale].Navigation['Content/FAQ'],
      to: '/content/faq',
      fontIcon: 'fas fa-question-circle'
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Content/CustomPage',
      tag: ['Content', 'Navbar'],
      name: i18n.messages[locale].Navigation['Content/Navbar'],
      to: '/content/navbar',
      fontIcon: 'fas fa-bars'
    },
    {
      _name: 'CSidebarNavTitle',
      _navName: 'Message',
      _children: ['Message']
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Message/Consult',
      tag: ['Message', 'Consult'],
      name: i18n.messages[locale].Navigation['Message/Consult'],
      to: '/message/consult',
      icon: 'cil-chat-bubble',
      // badge: {
      //   color: 'warning',
      //   text: '5',
      //   shape: 'pill'
      // }
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'Message/CommentFire',
      tag: ['Message', 'CommentFire'],
      name: i18n.messages[locale].Navigation['Message/CommentFire'],
      to: '/message/comment/fire',
      icon: 'cil-comment-square',
      // badge: {
      //     color: 'warning',
      //     text: '5',
      //     shape: 'pill'
      // }
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'Message/MailNotify',
      tag: ['Message', 'MailNotify'],
      name: i18n.messages[locale].Navigation['Message/MailNotify'],
      route: '/message/notify/email',
      icon: 'cil-envelope-closed',
      items: [
        {
          permissionLabel: 'MailTemplate',
          _navName: 'Message/MailTemplate',
          tag: ['Message', 'MailTemplate'],
          name: i18n.messages[locale].Navigation['Message/MailTemplate'],
          to: '/message/notify/email/template'
        },
        {
          permissionLabel: 'MailContent',
          _navName: 'Message/MailContent',
          tag: ['Message', 'MailContent'],
          name: i18n.messages[locale].Navigation['Message/MailContent'],
          to: '/message/notify/email/content'
        },
        {
          permissionLabel: 'MailSetting',
          _navName: 'Message/MailSetting',
          tag: ['Message', 'MailSetting'],
          name: i18n.messages[locale].Navigation['Message/MailSetting'],
          to: '/message/notify/email/setting'
        }
      ]
    },
    {
      _name: 'CSidebarNavTitle',
      _navName: 'ExperienceTicket',
      _children: ['ExperienceTicket']
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'ExperienceTicket/List',
      tag: ['ExperienceTicket', 'List'],
      name: i18n.messages[locale].Navigation['ExperienceTicket/List'],
      to: '/experience_ticket/list',
      icon: 'cil-notes'
    },
    {
      _name: 'CSidebarNavTitle',
      _navName: 'System',
      _children: ['System']
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'System/Basic',
      tag: ['System', 'Basic'],
      name: i18n.messages[locale].Navigation['System/Basic'],
      to: '/system/basic',
      icon: 'cil-settings'
    },
    {
      _name: 'AlterSidebarNavDropdown',
      _navName: 'System/Ecommerce',
      tag: ['System', 'Ecommerce'],
      name: i18n.messages[locale].Navigation['System/Ecommerce'],
      route: '/system/ecommerce',
      icon: 'cil-basket',
      items: [
        {
          permissionLabel: 'ShopSetting',
          _navName: 'System/ShopSetting',
          tag: ['System', 'ShopSetting'],
          name: i18n.messages[locale].Navigation['System/ShopSetting'],
          to: '/system/ecommerce/config'
        },
        {
          permissionLabel: 'Payment',
          _navName: 'System/Payment',
          tag: ['System', 'Payment'],
          name: i18n.messages[locale].Navigation['System/Payment'],
          to: '/system/ecommerce/payment'
        },
        {
          permissionLabel: 'Logistics',
          _navName: 'System/Logistics',
          tag: ['System', 'Logistics'],
          name: i18n.messages[locale].Navigation['System/Logistics'],
          to: '/system/ecommerce/logistics'
        },
        {
          permissionLabel: 'MerchantData',
          _navName: 'System/MerchantData',
          tag: ['System', 'MerchantData'],
          name: i18n.messages[locale].Navigation['System/MerchantData'],
          to: '/system/ecommerce/merchant',
          badge: {
            color: 'dark',
            text: '維護版本',
            shape: 'pill'
          }
        },
        {
          permissionLabel: 'Packing',
          _navName: 'System/Packing',
          tag: ['System', 'Packing'],
          name: i18n.messages[locale].Navigation['System/Packing'],
          to: '/system/ecommerce/packing'
        }
      ]
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'System/Themes',
      tag: ['System', 'Themes'],
      name: i18n.messages[locale].Navigation['System/Themes'],
      to: '/system/themes',
      icon: 'cil-grid'
    },
    {
      _name: 'AlterSidebarNavItem',
      _navName: 'System/Tracking',
      tag: ['System', 'Tracking'],
      name: i18n.messages[locale].Navigation['System/Tracking'],
      to: '/system/tracking',
      icon: 'cil-chart-line',
      badge: {
        color: 'dark',
        text: '維護版本',
        shape: 'pill'
      }
    },
    {
      _name: 'CSidebarNavDivider',
      _navName: 'Other/Divider',
      _class: 'm-2'
    }
  ]
}
