import Vue from 'vue'
import Firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/analytics'

try {
    Firebase.initializeApp({
        apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
        authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
        storageBucket: process.env.VUE_APP_FIREBASE_BUCKET,
        projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
        appId: process.env.VUE_APP_FIREBASE_APPID,
        measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENTID,
        databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
        messagingSenderId: process.env.VUE_APP_FIREBASE_SENDERID
    })
} catch (err) {
    if (!/already exists/.test(err.message)) {
        console.error('Firebase initialization error', err.stack)
    }
}

Vue.prototype.$Firebase = (_Service) => {
    if (_Service === 'app') {
        return Firebase
    }
    return Firebase[_Service]()
}

export const firestore = Firebase.firestore(),
    storage = Firebase.storage(),
    database = Firebase.database(),
    auth = Firebase.auth(),
    analytics = Firebase.analytics(),
    app = Firebase
