<template>
  <main>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <notifications group="notify" class="m-3" />
  </main>
</template>

<script>
  export default {
    name: 'Main',
    data() {
      return {
      }
    },
    mounted() {
    },
    methods: {
    }
  }
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
