import {
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibVimeoV,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibDiscord,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
  cibLine
} from '@coreui/icons'
import {
  cifAd,
  cifAe,
  cifAf,
  cifAg,
  cifAl,
  cifAm,
  cifAo,
  cifAr,
  cifAt,
  cifAu,
  cifAz,
  cifBa,
  cifBb,
  cifBd,
  cifBe,
  cifBf,
  cifBg,
  cifBh,
  cifBi,
  cifBj,
  cifBn,
  cifBo,
  cifBr,
  cifBs,
  cifBt,
  cifBw,
  cifBy,
  cifBz,
  cifCa,
  cifCd,
  cifCf,
  cifCg,
  cifCh,
  cifCi,
  cifCk,
  cifCl,
  cifCm,
  cifCn,
  cifCo,
  cifCr,
  cifCu,
  cifCv,
  cifCy,
  cifCz,
  cifDe,
  cifDj,
  cifDk,
  cifDm,
  cifDo,
  cifDz,
  cifEc,
  cifEe,
  cifEg,
  cifEr,
  cifEs,
  cifEt,
  cifFi,
  cifFj,
  cifFm,
  cifFr,
  cifGa,
  cifGb,
  cifGd,
  cifGe,
  cifGh,
  cifGm,
  cifGn,
  cifGq,
  cifGr,
  cifGt,
  cifGw,
  cifGy,
  cifHk,
  cifHn,
  cifHr,
  cifHt,
  cifHu,
  cifId,
  cifIe,
  cifIl,
  cifIn,
  cifIq,
  cifIr,
  cifIs,
  cifIt,
  cifJm,
  cifJo,
  cifJp,
  cifKe,
  cifKg,
  cifKh,
  cifKi,
  cifKm,
  cifKn,
  cifKp,
  cifKr,
  cifKw,
  cifKz,
  cifLa,
  cifLb,
  cifLc,
  cifLi,
  cifLk,
  cifLr,
  cifLs,
  cifLt,
  cifLu,
  cifLv,
  cifLy,
  cifMa,
  cifMc,
  cifMd,
  cifMe,
  cifMg,
  cifMh,
  cifMk,
  cifMl,
  cifMm,
  cifMn,
  cifMr,
  cifMt,
  cifMu,
  cifMv,
  cifMw,
  cifMx,
  cifMy,
  cifMz,
  cifNa,
  cifNe,
  cifNg,
  cifNi,
  cifNl,
  cifNo,
  cifNp,
  cifNr,
  cifNu,
  cifNz,
  cifOm,
  cifPa,
  cifPe,
  cifPg,
  cifPh,
  cifPk,
  cifPl,
  cifPt,
  cifPw,
  cifPy,
  cifQa,
  cifRo,
  cifRs,
  cifRu,
  cifRw,
  cifSa,
  cifSb,
  cifSc,
  cifSd,
  cifSe,
  cifSg,
  cifSi,
  cifSk,
  cifSl,
  cifSm,
  cifSn,
  cifSo,
  cifSr,
  cifSs,
  cifSt,
  cifSv,
  cifSy,
  cifSz,
  cifTd,
  cifTg,
  cifTh,
  cifTj,
  cifTl,
  cifTm,
  cifTn,
  cifTo,
  cifTr,
  cifTt,
  cifTv,
  cifTw,
  cifTz,
  cifUa,
  cifUg,
  cifUs,
  cifUy,
  cifUz,
  cifVa,
  cifVc,
  cifVe,
  cifVg,
  cifVn,
  cifWs,
  cifXk,
  cifYe,
  cifZa,
  cifZm,
  cifZw,
} from '@coreui/icons'
import {
  cil3d,
  cil4k,
  cilAccountLogout,
  cilActionRedo,
  cilAddressBook,
  cilActionUndo,
  cilAirplaneModeOff,
  cilAirplaneMode,
  cilAirplay,
  cilAlarm,
  cilAlbum,
  cilAlignCenter,
  cilAlignLeft,
  cilAmericanFootball,
  cilAperture,
  cilApple,
  cilApplicationsSettings,
  cilApplications,
  cilArrowBottom,
  cilAlignRight,
  cilArrowCircleBottom,
  cilArrowCircleLeft,
  cilArrowCircleRight,
  cilArrowLeft,
  cilArrowRight,
  cilArrowCircleTop,
  cilArrowThickFromBottom,
  cilArrowThickFromLeft,
  cilArrowThickBottom,
  cilArrowThickFromRight,
  cilArrowThickFromTop,
  cilArrowThickLeft,
  cilArrowThickRight,
  cilArrowThickToLeft,
  cilArrowThickToRight,
  cilArrowThickToTop,
  cilArrowThickToBottom,
  cilArrowThickTop,
  cilArrowTop,
  cilAssistiveListeningSystem,
  cilAsteriskCircle,
  cilAt,
  cilAsterisk,
  cilAudioDescription,
  cilAudioSpectrum,
  cilAvTimer,
  cilAudio,
  cilBadge,
  cilBan,
  cilBank,
  cilBalanceScale,
  cilBarChart,
  cilBarcode,
  cilBaseball,
  cilBasket,
  cilBath,
  cilBattery0,
  cilBattery3,
  cilBattery5,
  cilBatteryAlert,
  cilBatterySlash,
  cilBeachAccess,
  cilBeaker,
  cilBed,
  cilBell,
  cilBike,
  cilBirthdayCake,
  cilBlind,
  cilBasketball,
  cilBlurCircular,
  cilBlurLinear,
  cilBlur,
  cilBold,
  cilBoatAlt,
  cilBolt,
  cilBookmark,
  cilBook,
  cilBluetooth,
  cilBorderBottom,
  cilBorderClear,
  cilBorderHorizontal,
  cilBorderInner,
  cilBorderLeft,
  cilBorderOuter,
  cilBorderRight,
  cilBorderAll,
  cilBorderTop,
  cilBorderVertical,
  cilBowling,
  cilBraille,
  cilBriefcase,
  cilBrightness,
  cilBritishPound,
  cilBrowser,
  cilBrushAlt,
  cilBrush,
  cilBorderStyle,
  cilBug,
  cilBuilding,
  cilBullhorn,
  cilCalculator,
  cilBurger,
  cilCalendarCheck,
  cilCameraControl,
  cilBusAlt,
  cilCamera,
  cilCameraRoll,
  cilCalendar,
  cilCarAlt,
  cilCaretBottom,
  cilCaretLeft,
  cilCaretRight,
  cilCaretTop,
  cilCart,
  cilCasino,
  cilCast,
  cilCat,
  cilChartLine,
  cilChartPie,
  cilChart,
  cilCenterFocus,
  cilChatBubble,
  cilCheckAlt,
  cilCheckCircle,
  cilCheck,
  cilChevronCircleDownAlt,
  cilChevronCircleRightAlt,
  cilChevronCircleUpAlt,
  cilChevronBottom,
  cilChevronCircleLeftAlt,
  cilChevronDoubleLeft,
  cilChevronDoubleDown,
  cilChevronDoubleRight,
  cilChevronLeft,
  cilChevronDoubleUp,
  cilChevronRight,
  cilChevronTop,
  cilChild,
  cilChildFriendly,
  cilCircle,
  cilClearAll,
  cilClipboard,
  cilClock,
  cilClone,
  cilClosedCaptioning,
  cilCloudUpload,
  cilCloud,
  cilCloudy,
  cilCoffee,
  cilCode,
  cilColorBorder,
  cilColorFill,
  cilColorPalette,
  cilCloudDownload,
  cilCommentBubble,
  cilCommentSquare,
  cilCompass,
  cilCompress,
  cilContrast,
  cilContact,
  cilCopy,
  cilCouch,
  cilColumns,
  cilCreditCard,
  cilCropRotate,
  cilCrop,
  cilCut,
  cilCursor,
  cilDataTransferDown,
  cilCursorMove,
  cilDeaf,
  cilDescription,
  cilDialpad,
  cilDevices,
  cilDinner,
  cilDataTransferUp,
  cilDog,
  cilDollar,
  cilDoubleQuoteSansLeft,
  cilDoor,
  cilDoubleQuoteSansRight,
  cilDrinkAlcohol,
  cilDrink,
  cilDrop,
  cilElevator,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEqualizer,
  cilEthernet,
  cilExcerpt,
  cilExitToApp,
  cilExpandDown,
  cilExpandLeft,
  cilExpandRight,
  cilExpandUp,
  cilExposure,
  cilExternalLink,
  cilFaceDead,
  cilEyedropper,
  cilFace,
  cilFastfood,
  cilFax,
  cilFeaturedPlaylist,
  cilFile,
  cilFilterPhoto,
  cilEuro,
  cilFilter,
  cilFindInPage,
  cilFingerprint,
  cilFire,
  cilFilterFrames,
  cilFlightTakeoff,
  cilFlipToBack,
  cilFlipToFront,
  cilFlip,
  cilFlower,
  cilFlagAlt,
  cilFolder,
  cilFont,
  cilFolderOpen,
  cilFootball,
  cilFridge,
  cilFrown,
  cilFullscreenExit,
  cilFullscreen,
  cilFunctions,
  cilFunctionsAlt,
  cilGamepad,
  cilGarage,
  cilGem,
  cilFork,
  cilGif,
  cilGift,
  cilGlobeAlt,
  cilGolfAlt,
  cilGolf,
  cilGradient,
  cilGrain,
  cilGraph,
  cilGridSlash,
  cilGrid,
  cilHandPointDown,
  cilHandPointLeft,
  cilHd,
  cilHandPointUp,
  cilHeader,
  cilHdr,
  cilHeadphones,
  cilHealing,
  cilHighlighter,
  cilHighligt,
  cilHandPointRight,
  cilHome,
  cilHistory,
  cilHospital,
  cilHotTub,
  cilHouse,
  cilHttps,
  cilImageBroken,
  cilHeart,
  cilImagePlus,
  cilInbox,
  cilIndentIncrease,
  cilIndentDecrease,
  cilIndustrySlash,
  cilIndustry,
  cilInfinity,
  cilInfo,
  cilInputHdmi,
  cilInputPower,
  cilInstitution,
  cilItalic,
  cilInput,
  cilJustifyCenter,
  cilJustifyLeft,
  cilJustifyRight,
  cilKeyboard,
  cilLan,
  cilLaptop,
  cilLayers,
  cilLeaf,
  cilLemon,
  cilLevelDown,
  cilLevelUp,
  cilLibraryAdd,
  cilLifeRing,
  cilLanguage,
  cilLibrary,
  cilLightbulb,
  cilLineWeight,
  cilLineStyle,
  cilLineSpacing,
  cilLink,
  cilLinkBroken,
  cilListFilter,
  cilListHighPriority,
  cilListNumbered,
  cilListLowPriority,
  cilLinkAlt,
  cilListRich,
  cilLockLocked,
  cilLockUnlocked,
  cilLocationPin,
  cilList,
  cilLoop,
  cilLoop1,
  cilLoopCircular,
  cilLowVision,
  cilMap,
  cilLocomotive,
  cilMediaEject,
  cilMediaPause,
  cilMediaRecord,
  cilMediaPlay,
  cilMediaSkipBackward,
  cilMagnifyingGlass,
  cilMediaStepBackward,
  cilMediaStepForward,
  cilMediaStop,
  cilMedicalCross,
  cilMediaSkipForward,
  cilMeh,
  cilMemory,
  cilMicrophone,
  cilMinus,
  cilMenu,
  cilMobileLandscape,
  cilMobile,
  cilMoney,
  cilMonitor,
  cilMoodBad,
  cilMoodGood,
  cilMoodVeryBad,
  cilMoodVeryGood,
  cilMoon,
  cilMouse,
  cilMouthSlash,
  cilMove,
  cilMovie,
  cilMugTea,
  cilMusicNote,
  cilNotes,
  cilNewspaper,
  cilObjectGroup,
  cilObjectUngroup,
  cilOpacity,
  cilMug,
  cilPaint,
  cilPaintBucket,
  cilPaperPlane,
  cilPaperclip,
  cilParagraph,
  cilPenAlt,
  cilPaw,
  cilPenNib,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPizza,
  cilPin,
  cilPlaylistAdd,
  cilPlus,
  cilPool,
  cilPowerStandby,
  cilPregnant,
  cilPrint,
  cilQrCode,
  cilRain,
  cilRectangle,
  cilPuzzle,
  cilResizeHeight,
  cilReload,
  cilResizeWidth,
  cilRestaurant,
  cilRoom,
  cilRowing,
  cilRss,
  cilSatelite,
  cilSave,
  cilRunning,
  cilSchool,
  cilResizeBoth,
  cilScreenDesktop,
  cilScrubber,
  cilShareAll,
  cilSettings,
  cilShareAlt,
  cilShare,
  cilScreenSmartphone,
  cilShieldAlt,
  cilShareBoxed,
  cilShortText,
  cilShower,
  cilSignalCellular0,
  cilSignalCellular4,
  cilSignLanguage,
  cilSignalCellular3,
  cilSim,
  cilSitemap,
  cilSmilePlus,
  cilSmokeFree,
  cilSnowflake,
  cilSmokingRoom,
  cilSortAlphaDown,
  cilSortAlphaUp,
  cilSortAscending,
  cilSortDescending,
  cilSortNumericDown,
  cilSmile,
  cilSortNumericUp,
  cilSpa,
  cilSpeaker,
  cilSpaceBar,
  cilSpeech,
  cilSpreadsheet,
  cilSquare,
  cilStarHalf,
  cilStar,
  cilSpeedometer,
  cilStream,
  cilSun,
  cilStorage,
  cilSwapHorizontal,
  cilSwapVertical,
  cilSwimming,
  cilSync,
  cilTag,
  cilTablet,
  cilTags,
  cilTask,
  cilTaxi,
  cilTennisBall,
  cilTennis,
  cilTerminal,
  cilTerrain,
  cilTextShapes,
  cilTextSize,
  cilTextStrike,
  cilText,
  cilThumbUp,
  cilThumbDown,
  cilToggleOff,
  cilToilet,
  cilTextSquare,
  cilTransfer,
  cilTranslate,
  cilTrash,
  cilTouchApp,
  cilTriangle,
  cilTruck,
  cilTv,
  cilUnderline,
  cilUserFemale,
  cilUser,
  cilVector,
  cilUserUnfollow,
  cilUserFollow,
  cilVerticalAlignBottom,
  cilVerticalAlignTop,
  cilVideo,
  cilViewColumn,
  cilViewModule,
  cilViewQuilt,
  cilViewStream,
  cilVolumeHigh,
  cilVoiceOverRecord,
  cilVolumeLow,
  cilVolumeOff,
  cilWallet,
  cilWallpaper,
  cilWalk,
  cilWarning,
  cilWatch,
  cilVerticalAlignCenter,
  cilWc,
  cilWeightlifitng,
  cilWheelchair,
  cilWifiSignal0,
  cilWifiSignal1,
  cilWifiSignal4,
  cilWifiSignalOff,
  cilWifiSignal2,
  cilWindowMinimize,
  cilWindowMaximize,
  cilWindowRestore,
  cilWindow,
  cilXCircle,
  cilX,
  cilYen,
  cilWrapText,
  cilZoomIn,
  cilZoomOut
} from '@coreui/icons'
import {logo} from './logo'

export const iconsSet = Object.assign(
    {},
    {logo},
    {
      cil3d,
      cil4k,
      cilAccountLogout,
      cilActionRedo,
      cilAddressBook,
      cilActionUndo,
      cilAirplaneModeOff,
      cilAirplaneMode,
      cilAirplay,
      cilAlarm,
      cilAlbum,
      cilAlignCenter,
      cilAlignLeft,
      cilAmericanFootball,
      cilAperture,
      cilApple,
      cilApplicationsSettings,
      cilApplications,
      cilArrowBottom,
      cilAlignRight,
      cilArrowCircleBottom,
      cilArrowCircleLeft,
      cilArrowCircleRight,
      cilArrowLeft,
      cilArrowRight,
      cilArrowCircleTop,
      cilArrowThickFromBottom,
      cilArrowThickFromLeft,
      cilArrowThickBottom,
      cilArrowThickFromRight,
      cilArrowThickFromTop,
      cilArrowThickLeft,
      cilArrowThickRight,
      cilArrowThickToLeft,
      cilArrowThickToRight,
      cilArrowThickToTop,
      cilArrowThickToBottom,
      cilArrowThickTop,
      cilArrowTop,
      cilAssistiveListeningSystem,
      cilAsteriskCircle,
      cilAt,
      cilAsterisk,
      cilAudioDescription,
      cilAudioSpectrum,
      cilAvTimer,
      cilAudio,
      cilBadge,
      cilBan,
      cilBank,
      cilBalanceScale,
      cilBarChart,
      cilBarcode,
      cilBaseball,
      cilBasket,
      cilBath,
      cilBattery0,
      cilBattery3,
      cilBattery5,
      cilBatteryAlert,
      cilBatterySlash,
      cilBeachAccess,
      cilBeaker,
      cilBed,
      cilBell,
      cilBike,
      cilBirthdayCake,
      cilBlind,
      cilBasketball,
      cilBlurCircular,
      cilBlurLinear,
      cilBlur,
      cilBold,
      cilBoatAlt,
      cilBolt,
      cilBookmark,
      cilBook,
      cilBluetooth,
      cilBorderBottom,
      cilBorderClear,
      cilBorderHorizontal,
      cilBorderInner,
      cilBorderLeft,
      cilBorderOuter,
      cilBorderRight,
      cilBorderAll,
      cilBorderTop,
      cilBorderVertical,
      cilBowling,
      cilBraille,
      cilBriefcase,
      cilBrightness,
      cilBritishPound,
      cilBrowser,
      cilBrushAlt,
      cilBrush,
      cilBorderStyle,
      cilBug,
      cilBuilding,
      cilBullhorn,
      cilCalculator,
      cilBurger,
      cilCalendarCheck,
      cilCameraControl,
      cilBusAlt,
      cilCamera,
      cilCameraRoll,
      cilCalendar,
      cilCarAlt,
      cilCaretBottom,
      cilCaretLeft,
      cilCaretRight,
      cilCaretTop,
      cilCart,
      cilCasino,
      cilCast,
      cilCat,
      cilChartLine,
      cilChartPie,
      cilChart,
      cilCenterFocus,
      cilChatBubble,
      cilCheckAlt,
      cilCheckCircle,
      cilCheck,
      cilChevronCircleDownAlt,
      cilChevronCircleRightAlt,
      cilChevronCircleUpAlt,
      cilChevronBottom,
      cilChevronCircleLeftAlt,
      cilChevronDoubleLeft,
      cilChevronDoubleDown,
      cilChevronDoubleRight,
      cilChevronLeft,
      cilChevronDoubleUp,
      cilChevronRight,
      cilChevronTop,
      cilChild,
      cilChildFriendly,
      cilCircle,
      cilClearAll,
      cilClipboard,
      cilClock,
      cilClone,
      cilClosedCaptioning,
      cilCloudUpload,
      cilCloud,
      cilCloudy,
      cilCoffee,
      cilCode,
      cilColorBorder,
      cilColorFill,
      cilColorPalette,
      cilCloudDownload,
      cilCommentBubble,
      cilCommentSquare,
      cilCompass,
      cilCompress,
      cilContrast,
      cilContact,
      cilCopy,
      cilCouch,
      cilColumns,
      cilCreditCard,
      cilCropRotate,
      cilCrop,
      cilCut,
      cilCursor,
      cilDataTransferDown,
      cilCursorMove,
      cilDeaf,
      cilDescription,
      cilDialpad,
      cilDevices,
      cilDinner,
      cilDataTransferUp,
      cilDog,
      cilDollar,
      cilDoubleQuoteSansLeft,
      cilDoor,
      cilDoubleQuoteSansRight,
      cilDrinkAlcohol,
      cilDrink,
      cilDrop,
      cilElevator,
      cilEnvelopeClosed,
      cilEnvelopeLetter,
      cilEnvelopeOpen,
      cilEqualizer,
      cilEthernet,
      cilExcerpt,
      cilExitToApp,
      cilExpandDown,
      cilExpandLeft,
      cilExpandRight,
      cilExpandUp,
      cilExposure,
      cilExternalLink,
      cilFaceDead,
      cilEyedropper,
      cilFace,
      cilFastfood,
      cilFax,
      cilFeaturedPlaylist,
      cilFile,
      cilFilterPhoto,
      cilEuro,
      cilFilter,
      cilFindInPage,
      cilFingerprint,
      cilFire,
      cilFilterFrames,
      cilFlightTakeoff,
      cilFlipToBack,
      cilFlipToFront,
      cilFlip,
      cilFlower,
      cilFlagAlt,
      cilFolder,
      cilFont,
      cilFolderOpen,
      cilFootball,
      cilFridge,
      cilFrown,
      cilFullscreenExit,
      cilFullscreen,
      cilFunctions,
      cilFunctionsAlt,
      cilGamepad,
      cilGarage,
      cilGem,
      cilFork,
      cilGif,
      cilGift,
      cilGlobeAlt,
      cilGolfAlt,
      cilGolf,
      cilGradient,
      cilGrain,
      cilGraph,
      cilGridSlash,
      cilGrid,
      cilHandPointDown,
      cilHandPointLeft,
      cilHd,
      cilHandPointUp,
      cilHeader,
      cilHdr,
      cilHeadphones,
      cilHealing,
      cilHighlighter,
      cilHighligt,
      cilHandPointRight,
      cilHome,
      cilHistory,
      cilHospital,
      cilHotTub,
      cilHouse,
      cilHttps,
      cilImageBroken,
      cilHeart,
      cilImagePlus,
      cilInbox,
      cilIndentIncrease,
      cilIndentDecrease,
      cilIndustrySlash,
      cilIndustry,
      cilInfinity,
      cilInfo,
      cilInputHdmi,
      cilInputPower,
      cilInstitution,
      cilItalic,
      cilInput,
      cilJustifyCenter,
      cilJustifyLeft,
      cilJustifyRight,
      cilKeyboard,
      cilLan,
      cilLaptop,
      cilLayers,
      cilLeaf,
      cilLemon,
      cilLevelDown,
      cilLevelUp,
      cilLibraryAdd,
      cilLifeRing,
      cilLanguage,
      cilLibrary,
      cilLightbulb,
      cilLineWeight,
      cilLineStyle,
      cilLineSpacing,
      cilLink,
      cilLinkBroken,
      cilListFilter,
      cilListHighPriority,
      cilListNumbered,
      cilListLowPriority,
      cilLinkAlt,
      cilListRich,
      cilLockLocked,
      cilLockUnlocked,
      cilLocationPin,
      cilList,
      cilLoop,
      cilLoop1,
      cilLoopCircular,
      cilLowVision,
      cilMap,
      cilLocomotive,
      cilMediaEject,
      cilMediaPause,
      cilMediaRecord,
      cilMediaPlay,
      cilMediaSkipBackward,
      cilMagnifyingGlass,
      cilMediaStepBackward,
      cilMediaStepForward,
      cilMediaStop,
      cilMedicalCross,
      cilMediaSkipForward,
      cilMeh,
      cilMemory,
      cilMicrophone,
      cilMinus,
      cilMenu,
      cilMobileLandscape,
      cilMobile,
      cilMoney,
      cilMonitor,
      cilMoodBad,
      cilMoodGood,
      cilMoodVeryBad,
      cilMoodVeryGood,
      cilMoon,
      cilMouse,
      cilMouthSlash,
      cilMove,
      cilMovie,
      cilMugTea,
      cilMusicNote,
      cilNotes,
      cilNewspaper,
      cilObjectGroup,
      cilObjectUngroup,
      cilOpacity,
      cilMug,
      cilPaint,
      cilPaintBucket,
      cilPaperPlane,
      cilPaperclip,
      cilParagraph,
      cilPenAlt,
      cilPaw,
      cilPenNib,
      cilOptions,
      cilPencil,
      cilPeople,
      cilPhone,
      cilPizza,
      cilPin,
      cilPlaylistAdd,
      cilPlus,
      cilPool,
      cilPowerStandby,
      cilPregnant,
      cilPrint,
      cilQrCode,
      cilRain,
      cilRectangle,
      cilPuzzle,
      cilResizeHeight,
      cilReload,
      cilResizeWidth,
      cilRestaurant,
      cilRoom,
      cilRowing,
      cilRss,
      cilSatelite,
      cilSave,
      cilRunning,
      cilSchool,
      cilResizeBoth,
      cilScreenDesktop,
      cilScrubber,
      cilShareAll,
      cilSettings,
      cilShareAlt,
      cilShare,
      cilScreenSmartphone,
      cilShieldAlt,
      cilShareBoxed,
      cilShortText,
      cilShower,
      cilSignalCellular0,
      cilSignalCellular4,
      cilSignLanguage,
      cilSignalCellular3,
      cilSim,
      cilSitemap,
      cilSmilePlus,
      cilSmokeFree,
      cilSnowflake,
      cilSmokingRoom,
      cilSortAlphaDown,
      cilSortAlphaUp,
      cilSortAscending,
      cilSortDescending,
      cilSortNumericDown,
      cilSmile,
      cilSortNumericUp,
      cilSpa,
      cilSpeaker,
      cilSpaceBar,
      cilSpeech,
      cilSpreadsheet,
      cilSquare,
      cilStarHalf,
      cilStar,
      cilSpeedometer,
      cilStream,
      cilSun,
      cilStorage,
      cilSwapHorizontal,
      cilSwapVertical,
      cilSwimming,
      cilSync,
      cilTag,
      cilTablet,
      cilTags,
      cilTask,
      cilTaxi,
      cilTennisBall,
      cilTennis,
      cilTerminal,
      cilTerrain,
      cilTextShapes,
      cilTextSize,
      cilTextStrike,
      cilText,
      cilThumbUp,
      cilThumbDown,
      cilToggleOff,
      cilToilet,
      cilTextSquare,
      cilTransfer,
      cilTranslate,
      cilTrash,
      cilTouchApp,
      cilTriangle,
      cilTruck,
      cilTv,
      cilUnderline,
      cilUserFemale,
      cilUser,
      cilVector,
      cilUserUnfollow,
      cilUserFollow,
      cilVerticalAlignBottom,
      cilVerticalAlignTop,
      cilVideo,
      cilViewColumn,
      cilViewModule,
      cilViewQuilt,
      cilViewStream,
      cilVolumeHigh,
      cilVoiceOverRecord,
      cilVolumeLow,
      cilVolumeOff,
      cilWallet,
      cilWallpaper,
      cilWalk,
      cilWarning,
      cilWatch,
      cilVerticalAlignCenter,
      cilWc,
      cilWeightlifitng,
      cilWheelchair,
      cilWifiSignal0,
      cilWifiSignal1,
      cilWifiSignal4,
      cilWifiSignalOff,
      cilWifiSignal2,
      cilWindowMinimize,
      cilWindowMaximize,
      cilWindowRestore,
      cilWindow,
      cilXCircle,
      cilX,
      cilYen,
      cilWrapText,
      cilZoomIn,
      cilZoomOut
    },
    {
      cifAd,
      cifAe,
      cifAf,
      cifAg,
      cifAl,
      cifAm,
      cifAo,
      cifAr,
      cifAt,
      cifAu,
      cifAz,
      cifBa,
      cifBb,
      cifBd,
      cifBe,
      cifBf,
      cifBg,
      cifBh,
      cifBi,
      cifBj,
      cifBn,
      cifBo,
      cifBr,
      cifBs,
      cifBt,
      cifBw,
      cifBy,
      cifBz,
      cifCa,
      cifCd,
      cifCf,
      cifCg,
      cifCh,
      cifCi,
      cifCk,
      cifCl,
      cifCm,
      cifCn,
      cifCo,
      cifCr,
      cifCu,
      cifCv,
      cifCy,
      cifCz,
      cifDe,
      cifDj,
      cifDk,
      cifDm,
      cifDo,
      cifDz,
      cifEc,
      cifEe,
      cifEg,
      cifEr,
      cifEs,
      cifEt,
      cifFi,
      cifFj,
      cifFm,
      cifFr,
      cifGa,
      cifGb,
      cifGd,
      cifGe,
      cifGh,
      cifGm,
      cifGn,
      cifGq,
      cifGr,
      cifGt,
      cifGw,
      cifGy,
      cifHk,
      cifHn,
      cifHr,
      cifHt,
      cifHu,
      cifId,
      cifIe,
      cifIl,
      cifIn,
      cifIq,
      cifIr,
      cifIs,
      cifIt,
      cifJm,
      cifJo,
      cifJp,
      cifKe,
      cifKg,
      cifKh,
      cifKi,
      cifKm,
      cifKn,
      cifKp,
      cifKr,
      cifKw,
      cifKz,
      cifLa,
      cifLb,
      cifLc,
      cifLi,
      cifLk,
      cifLr,
      cifLs,
      cifLt,
      cifLu,
      cifLv,
      cifLy,
      cifMa,
      cifMc,
      cifMd,
      cifMe,
      cifMg,
      cifMh,
      cifMk,
      cifMl,
      cifMm,
      cifMn,
      cifMr,
      cifMt,
      cifMu,
      cifMv,
      cifMw,
      cifMx,
      cifMy,
      cifMz,
      cifNa,
      cifNe,
      cifNg,
      cifNi,
      cifNl,
      cifNo,
      cifNp,
      cifNr,
      cifNu,
      cifNz,
      cifOm,
      cifPa,
      cifPe,
      cifPg,
      cifPh,
      cifPk,
      cifPl,
      cifPt,
      cifPw,
      cifPy,
      cifQa,
      cifRo,
      cifRs,
      cifRu,
      cifRw,
      cifSa,
      cifSb,
      cifSc,
      cifSd,
      cifSe,
      cifSg,
      cifSi,
      cifSk,
      cifSl,
      cifSm,
      cifSn,
      cifSo,
      cifSr,
      cifSs,
      cifSt,
      cifSv,
      cifSy,
      cifSz,
      cifTd,
      cifTg,
      cifTh,
      cifTj,
      cifTl,
      cifTm,
      cifTn,
      cifTo,
      cifTr,
      cifTt,
      cifTv,
      cifTw,
      cifTz,
      cifUa,
      cifUg,
      cifUs,
      cifUy,
      cifUz,
      cifVa,
      cifVc,
      cifVe,
      cifVg,
      cifVn,
      cifWs,
      cifXk,
      cifYe,
      cifZa,
      cifZm,
      cifZw
    },
    {
      cibVimeoV,
      cibFacebook,
      cibTwitter,
      cibLinkedin,
      cibFlickr,
      cibTumblr,
      cibXing,
      cibGithub,
      cibYoutube,
      cibDribbble,
      cibInstagram,
      cibPinterest,
      cibVk,
      cibYahoo,
      cibBehance,
      cibReddit,
      cibVimeo,
      cibCcMastercard,
      cibCcVisa,
      cibStripe,
      cibPaypal,
      cibGooglePay,
      cibCcAmex,
      cibLine,
      cibDiscord
    }
)
