import Vue from 'vue'
import Router from 'vue-router'
import routes from 'vue-auto-routing'
import i18n from '../plugins/i18n'
import store from '../store'
import { auth } from '../plugins/firebase'
import { createRouterLayout } from 'vue-router-layout'

Vue.use(Router)

const RouterLayout = createRouterLayout(layout => {
    return import('@/layouts/' + layout + '.vue')
})

routes.push(
    {
        component: () => import('../pages/products/detail/_id'),
        meta: {
            label: i18n.messages[store.state.locale].Navigation['Products/Add'],
        },
        name: "products-detail-add",
        path: "products/add"
    },
    {
      component: () => import('../pages/products/plan/detail/_id'),
      meta: {
        label: i18n.messages[store.state.locale].Navigation['Products/AddPlan'],
      },
      name: "products-detail-plan-add",
      path: "products/plan/add"
    },
    {
      component: () => import('../pages/products/stock/detail/_id'),
      meta: {
        label: i18n.messages[store.state.locale].Navigation['Products/AddSKU'],
      },
      name: "products-detail-stock-add",
      path: "products/stock/add"
    },
    {
      component: () => import('../pages/offerItems/giftDetail/_id'),
      meta: {
        label: i18n.messages[store.state.locale].Navigation['Products/AddGift'],
      },
      name: "products-gift-detail-add",
      path: "offerItems/gift/add"
    },
    {
      component: () => import('../pages/offerItems/addonDetail/_id'),
      meta: {
        label: i18n.messages[store.state.locale].Navigation['Products/AddExtraItems'],
      },
      name: "products-addon-detail-add",
      path: "offerItems/addon/add"
    },
    {
        component: () => import('../pages/products/category/detail/_slug'),
        meta: {
            label: i18n.messages[store.state.locale].Navigation['Products/AddCategory'],
        },
        name: "products-category-add",
        path: "products/category/add"
    },
    {
        component: () => import('../pages/post/detail/_slug'),
        meta: {
            label: i18n.messages[store.state.locale].Navigation['Post/Add'],
        },
        name: "post-detail-add",
        path: "post/add"
    },
    {
        component: () => import('../pages/post/category/detail/_slug'),
        meta: {
            label: i18n.messages[store.state.locale].Navigation['Post/AddCategory'],
        },
        name: "post-category-add",
        path: "post/category/add"
    },
    {
        component: () => import('../pages/video/detail/_slug'),
        meta: {
            label: i18n.messages[store.state.locale].Navigation['Video/Add'],
        },
        name: "video-detail-add",
        path: "video/add"
    },
    {
        component: () => import('../pages/video/category/detail/_slug'),
        meta: {
            label: i18n.messages[store.state.locale].Navigation['Video/AddCategory'],
        },
        name: "video-category-add",
        path: "video/category/add"
    },
    {
      component: () => import('../pages/channel/landing/detail/_slug'),
      meta: {
        label: i18n.messages[store.state.locale].Navigation['Products/SinglePageAdd'],
      },
      name: "product-single-page-detail-add",
      path: "channel/landing/add"
    },
    {
      component: () => import('../pages/channel/store/detail/_slug'),
      meta: {
        label: i18n.messages[store.state.locale].Navigation['Channel/StoreAdd'],
      },
      name: "store-detail-add",
      path: "channel/store/add"
    },
    {
      component: () => import('../pages/offer/coupon/detail/_id'),
      meta: {
        label: i18n.messages[store.state.locale].Navigation['Offer/AddCoupon'],
      },
      name: "coupon-detail-add",
      path: "offer/coupon/add"
    },
    {
      component: () => import('../pages/offer/discountTicket/detail/_id'),
      meta: {
        label: i18n.messages[store.state.locale].Navigation['Offer/AddDiscountTicket'],
      },
      name: "discount-ticket-detail-add",
      path: "offer/discountTicket/add"
    },
    {
      component: () => import('../pages/offer/activity/detail/_id'),
      meta: {
        label: i18n.messages[store.state.locale].Navigation['Offer/AddActivity'],
      },
      name: "activity-detail-add",
      path: "offer/activity/add"
    },
    {
      component: () => import('../pages/offer/optionalSelect/detail/_id'),
      meta: {
        label: i18n.messages[store.state.locale].Navigation['Offer/AddOptionalSelect'],
      },
      name: "optionalSelect-detail-add",
      path: "offer/optionalSelect/add"
    },
    {
      component: () => import('../pages/offer/extraItems/detail/_id'),
      meta: {
        label: i18n.messages[store.state.locale].Navigation['Offer/AddExtraItems'],
      },
      name: "extraItems-detail-add",
      path: "offer/extraItems/add"
    },
    {
      component: () => import('../pages/content/page/detail/_slug'),
      meta: {
        label: i18n.messages[store.state.locale].Navigation['Content/CustomPageAdd'],
      },
      name: "custom-page-detail-add",
      path: "content/page/add"
    },
    {
      component: () => import('../pages/content/faq/_slug'),
      meta: {
        label: i18n.messages[store.state.locale].Navigation['Content/AddFAQ'],
      },
      name: "content-faq-add",
      path: "content/faq-add"
    },
)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            meta: {
                label: i18n.messages[store.state.locale].Navigation['Home'],
            },
            component: RouterLayout,
            children: routes
        }
    ]
})

router.beforeEach((to, from, next) => {
    const PermissionSession = JSON.parse(localStorage.getItem('Permission')) || {}
    if (Object.keys(store.state.user.permission).length === 0 && Object.keys(PermissionSession).length > 0) {
        store.commit('setPermission', PermissionSession)
    }
    if (auth.currentUser) {
      store.dispatch('getIdToken', auth.currentUser).catch(() => {
        store.dispatch('Logout').then(() => {
          return next('/login')
        })
      })
    }
    if (to.meta.label) {
        document.title = to.meta.label + ' - ' + (PermissionSession.ProjectTitle ? PermissionSession.ProjectTitle : process.env.VUE_APP_PLATFORM_TITLE)
    }
    return next()
})

export default router
