import Vue from 'vue'
import Axios from 'axios'

const Instance = Axios.create({
	timeout: 120 * 1000 // Timeout
})

Vue.prototype.$axios = Instance

export default Instance
